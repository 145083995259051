/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta, title, image: metaImage, pathname, keywords, author, dates, addJsonLdMetadata }) => {

  //console.log({ description, lang, meta, title, image: metaImage, pathname, keywords, author, dates, addJsonLdMetadata });

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            siteUrl
            image
            social {
              twitter
              facebook
              linkedin
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const image = metaImage && metaImage.src ? `${site.siteMetadata.siteUrl}${metaImage.src}` : `${site.siteMetadata.siteUrl}/${site.siteMetadata.image}`;
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;
  const authorName = author && author.name;
  const authorUrl = author && author.websiteUrl;
  const publishedDate = dates && dates.published;
  const modifiedDate = dates && dates.modified;

  let jsonSchema;
  if (addJsonLdMetadata) {
    jsonSchema = {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": title,
      "image": image,
      "publisher": {
        "@type": "Organization",
        "name": site.siteMetadata.title,
        "url": site.siteMetadata.siteUrl,
        "logo": {
          "@type": "ImageObject",
          "url": "https://k2vacademy.com/images/k2v-academy-logo.png",
          "width": "461",
          "height": "461"
        }
      },
      "url": canonical,
      "datePublished": publishedDate,
      "dateCreated": publishedDate,
      "dateModified": modifiedDate,
      "description": metaDescription,
      "author": {
        "@type": "Person",
        "name": authorName,
        "url": authorUrl
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": site.siteMetadata.siteUrl
      }
    };
    //console.log(jsonSchema);
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords ? keywords.join(',') : site.siteMetadata.keywords.join(','),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat([
        {
          property: "og:image",
          content: image,
        },
        {
          property: "og:image:width",
          content: metaImage ? metaImage.width : 1200,
        },
        {
          property: "og:image:height",
          content: metaImage ? metaImage.height : 675,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
      ])
      .concat(meta)}
    >
      {addJsonLdMetadata && <script type="application/ld+json">{JSON.stringify(jsonSchema)}</script>}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default Seo
