import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Navbar = () => {

  const handleClick = () => {
    const bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.classList.contains('enlarged') ? bodyElement.classList.remove('enlarged') : bodyElement.classList.add('enlarged');
  };

  return (
    <div>
      {/* Top Bar */}
      <div className="topbar-mobile">
        <div className="logo">
          <Link to="/">
            <StaticImage
              src="../images/k2v-logo.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="K2V Academy Logo"
              formats={["auto", "webp"]}
              style={{ width: '60px' }} />
          </Link>
          <button className="button-menu-mobile" style={{marginTop: '-50px'}} onClick={handleClick}>
            <i className="icon-menu"></i>
          </button>
        </div>
      </div>

      {/* Left Side Bar */}
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          <div className="logo">
            <Link to="/">
              <StaticImage
                src="../images/k2v-logo.png"
                layout="fullWidth"
                placeholder="blurred"
                alt="K2V Academy Logo"
                formats={["auto", "webp"]}
                style={{ width: '150px', margin: 'auto' }} />
            </Link>
            <p className="text-muted" style={{textAlign: 'center'}}>Dedicated to bring you best content in Development, Cloud and DevOps since 2014.</p>
          </div>
          <div id="sidebar-menu">
            <ul className="metismenu" id="side-menu">

              <li key="home">
                <Link to="/"><span> Home</span></Link>
              </li>

              <li key="about">
                <Link to="/about">About</Link>
              </li>

              <li key="connect">
                <Link to="/contact">Contact</Link>
              </li>

            </ul>

            <div className="copyright-box">
              <p>2020 © K2V Academy</p>
            </div>

          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;